/* This file adds custom utilities style to the tailwind layer */
/* This enables the custom styles to be used with tailwind features such as variant, media queries and tree shaking  */

/* Alternatives: */
/* If you are looking to extend the base style, add your style to retail-base.css */
/* If you are looking to use component specific css: */
/*                           - use .module.css in the component folder  */
/*                           - or add your style to retail-components.css  */
@layer utilities {
  .truncate-2-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .grow-on-hover:hover {
    transform: scale(1.1) translateY(-5px);
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }

  /* --------------- */
  /* Custom scrollbar for dropdown menus */
  /* ---- */
  .tapestry-scrollbar::-webkit-scrollbar {
    width: 5px;
  }

  .tapestry-scrollbar::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }

  .tapestry-scrollbar::-webkit-scrollbar-thumb {
    background-color: #889aa4;
    border-radius: 8px;
    height: 15px;
  }

  .tapestry-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #6c7a82;
  }

  /* Firefox 64 */
  .tapestry-scrollbar {
    scrollbar-color: #889aa4 #fff;
    scrollbar-width: thin;
    border-radius: 8px;
  }

  /* ---------------------------------------------------------------- */
  /* ------------ POINTER ARROW FOR DROPDOWN MENU ------------------- */
  /* ----------------------------------------------------------------  */
  /* Add more as needed */
  .box-arrow-top-right:after,
  .box-arrow-top-right:before {
    position: absolute;
    bottom: 100%;
    left: 99%;
    content: ' ';
    height: 0;
    width: 0;
    border: solid transparent;
    border-color: #eee;
    border-bottom-color: #ffffff;
    pointer-events: none;
  }

  .box-arrow-top-right:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 12px;
    margin-left: -28px;
  }
  .box-arrow-top-right:before {
    border-color: rgba(238, 238, 238, 0);
    border-bottom-color: #eee;
    border-width: 13px;
    margin-left: -29px;
  }

  .box-arrow-bottom-center:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: inherit;
    border-width: 10px;
    margin-left: -10px;
  }
}

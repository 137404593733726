.react-calendar {
  line-height: 1.125em;
  /* * The properties below are done via Tailwind directly in the `Calendar` component */
  /* width: 320px; */
  /* max-width: 100%; */
  /* background: white; */
  /* box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.116), 0 10px 10px -5px rgba(0, 0, 0, 0.281); */
  /* padding: 1rem; */
  /* color: #666666; */
  /* border-radius: 0.5rem; */
}

.react-calendar--doubleView .react-calendar__viewContainer > div:first-child {
  margin-bottom: 1rem;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  color: #666666;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  color: #666666;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  background: none;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.25rem;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  color: #ed7725;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #86b8dc;
}
.react-calendar__tile--active {
  background: #86b8dc;
  color: white;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background: #0e73bb;
  color: #fff;
}
.react-calendar__tile--range,
.react-calendar__tile--hover {
  border-radius: 0;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--hoverStart {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.react-calendar__tile--rangeEnd,
.react-calendar__tile--hoverEnd {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #0b5c95;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

@media screen and (min-width: 481px) {
  .react-calendar--doubleView {
    width: 640px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > div:first-child {
    margin-bottom: 0;
  }
}

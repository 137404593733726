/* This file adds custom components style to the tailwind layer */
/* This enables the custom styles to be used with tailwind features such as variant, media queries and tree shaking  */

/* Alternatives: */
/* If you are looking to extend the base style, add your style to retail-base.css */
/* If you are looking to add utilities, add your style to retail-utilities.css */
/* If your component is conditionnally render, then it might be preferable to include the style outside of the @layer directive */
@layer components {
}

/* Carousel Related */
.carousel__slider-tray-wrapper {
  max-width: 768px;
  overflow-x: hidden;
  margin: 0 auto;
}

.carousel__slider-tray {
  display: flex !important;
  flex-direction: row;
  align-items: flex-start;
}

.carousel__slide {
  padding-bottom: 0 !important;
}

.carousel__dot {
  @apply bg-gray-200;
}

.carousel__dot--selected {
  background-color: rgba(14, 115, 187, 1) !important;
}

.carousel__max-width {
  max-width: 728px;
}
/* end of carousel */

/* ------------------------------------------- */
/* Phone Input */
/* --------------------------------- */
/* CSS variables. */
:root {
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
}

.PhoneInput {
  @apply flex items-center;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  /* flex: 1; */
  /* The phone number input should shrink
  to make room for the extension input */
  /* min-width: 0; */
  @apply border-gray-border focus:border-orange-hank min-w-0 flex-1 rounded-md border bg-white py-4 pl-6 text-base font-normal tracking-wider text-black focus:outline-none;
}

.PhoneInputCountry {
  @apply border-gray-border xs:mr-4 relative mr-2 flex items-center self-stretch rounded-md border px-4;
}
.PhoneInputCountry:focus-within {
  @apply border-orange;
}

.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  @apply h-4;
}

.PhoneInputCountryIcon--square {
  @apply w-4;
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
   and sometime there can be seen white pixels of the background at top and bottom. */
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
   and sometime there can be seen white pixels of the background at top and bottom,
   so an additional "inset" border is added. */
  @apply bg-black shadow-inner;
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  /* display: block; */
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  @apply block h-full w-full;
}

.PhoneInputInternationalIconPhone {
  @apply opacity-75;
}

.PhoneInputInternationalIconGlobe {
  @apply opacity-75;
}

/* Styling native country `<select/>`. */
.PhoneInputCountrySelect {
  @apply absolute top-0 left-0 z-10 h-full w-full cursor-pointer border-none opacity-0;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  @apply cursor;
}

.PhoneInputCountrySelectArrow {
  content: '';
  @apply ml-4 block h-2 w-2 rotate-45 transform border-r border-b border-current opacity-50;
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  @apply text-orange opacity-100;
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  @apply text-orange opacity-100;
}

@charset "UTF-8";
/* app.css should be on top */
@import './app.css';
@import 'tailwindcss/base';
@import './market-base.css';

@import 'tailwindcss/components';
@import './market-components.css';

@import 'tailwindcss/utilities';
@import './market-utilities.css';

/* third-party stylesheets */
@import './calendar.css';
